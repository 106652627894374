.loading-square-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #333;
  }
  
  .loading-square {
    width: 200px;
    height: 200px;
    background: #444;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .loading-square-inner {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      #555,
      transparent
    );
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  
  @media (max-width: 900px) {
    .loading-square {
      width: 180px;
      height: 180px;
    }
  }
  
  @media (max-width: 600px) {
    .loading-square {
      width: 150px;
      height: 150px;
    }
  }
  
  @media (max-width: 400px) {
    .loading-square {
      width: 120px;
      height: 120px;
    }
  }