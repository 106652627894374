/* src/styles/AuthPopup.css */

.auth-popup {
  position: fixed;
  top: -50px;
  left: 0;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1000;
  transition: top 0.3s ease-in-out;
}

.auth-popup.visible {
  top: 0;
}

.auth-popup-content {
  background-color: #444;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.auth-popup p {
  color: white;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscription-popup {
  position: fixed;
  top: 0px; /* Начальная позиция ниже auth-popup */
  left: 0;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 999;
  transition: top 0.3s ease-in-out;
}

.subscription-popup.visible {
  top: 50px; /* Смещение вниз после показа */
}

.subscription-popup-content {
  background-color: #444;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.subscription-popup p {
  color: white;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
