/* styles.css */

/* Общие стили для кнопок */
.simple-button {
  width: 200px;
  height: 200px;
  background: #444;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  box-sizing: border-box;
  line-height: 1.2; /* Для улучшения расстояния между строками */
}

/* Стили при наведении на кнопку */
.simple-button:hover {
  background: #555;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}


/* Адаптивные стили для кнопок на экранах до 480px (типичный размер для смартфонов) */
@media (max-width: 480px) {
  .simple-button {
    width: 140px;
    height: 140px;
    font-size: 13px;
    padding: 10px;
  }
}

/* Адаптивные стили для кнопок на очень маленьких экранах */
@media (max-width: 360px) {
  .simple-button {
    width: 120px;
    height: 120px;
    font-size: 11px;
    padding: 8px;
  }
}

/* Стили для контейнера кнопок */
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 660px;
}

/* Общие стили для всех страниц */
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 60px 20px;
  box-sizing: border-box;
  background-color: #333;
}

/* Контейнер для центрирования контента */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
