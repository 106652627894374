  /* Стили для обертки контента и кнопок */
  .markdown-wrapper {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

  /* Стили для содержимого Markdown */
  .markdown-content {
    background: #1e1e1e;
    color: #d4d4d4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    line-height: 1.6;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    margin: 20px 0; /* Добавляем отступ сверху и снизу */
  }

  /* Стили для заголовков Markdown */
  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3,
  .markdown-content h4,
  .markdown-content h5,
  .markdown-content h6 {
    color: #569cd6;
    margin-top: 24px;
    margin-bottom: 16px;
    border-bottom: 1px solid #333;
    padding-bottom: 8px;
    font-size: 1.5em;
  }
  
  /* Стили для параграфов Markdown */
  .markdown-content p {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  /* Стили для ссылок в Markdown */
  .markdown-content a {
    color: #d7ba7d;
    text-decoration: none;
  }
  
  .markdown-content a:hover {
    text-decoration: underline;
  }
  
  /* Стили для списков в Markdown */
  .markdown-content ul,
  .markdown-content ol {
    padding-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .markdown-content li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  /* Стили для цитат в Markdown */
  .markdown-content blockquote {
    margin: 16px 0;
    padding-left: 20px;
    border-left: 5px solid #d4d4d4;
    color: #d4d4d4;
    background: #2d2d2d;
  }
  
  /* Стили для кода в Markdown */
  .markdown-content code {
    background: #2d2d2d;
    color: #dcdcaa;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
  }
  
  /* Стили для предварительного кода в Markdown */
  .markdown-content pre {
    background: #2d2d2d;
    color: #dcdcaa;
    padding: 12px;
    border-radius: 4px;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  /* Адаптивные стили для содержимого Markdown */
  @media (max-width: 900px) {
    .markdown-content {
      padding: 15px;
      font-size: 16px;
    }
  
    .markdown-content h1,
    .markdown-content h2,
    .markdown-content h3,
    .markdown-content h4,
    .markdown-content h5,
    .markdown-content h6 {
      font-size: 1.4em;
    }
  }
  
  @media (max-width: 600px) {
    .markdown-content {
      padding: 10px;
      font-size: 14px;
    }
  
    .markdown-content h1,
    .markdown-content h2,
    .markdown-content h3,
    .markdown-content h4,
    .markdown-content h5,
    .markdown-content h6 {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 400px) {
    .markdown-content {
      padding: 5px;
      font-size: 12px;
    }
  
    .markdown-content h1,
    .markdown-content h2,
    .markdown-content h3,
    .markdown-content h4,
    .markdown-content h5,
    .markdown-content h6 {
      font-size: 1em;
    }
  }
    


  /* Стили для группы кнопок */
  .button-group {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
  }

  /* Стили для кнопок */
  .custom-button {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: bold;
      color: #ffffff;
      background-color: #4a4a4a;
  }

  .custom-button:hover {
      transform: translateY(-2px);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      background-color: #5a5a5a;
  }

  /* Медиа-запрос для мобильных устройств */
  @media (max-width: 600px) {
      .custom-button {
          padding: 8px 15px;
          font-size: 14px;
      }

      .markdown-content {
          font-size: 14px;
          margin: 15px 0;
      }
  }

  @media (max-width: 400px) {
      .markdown-content {
          font-size: 12px;
          margin: 10px 0;
      }
  }