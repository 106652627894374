/* auth-forms.css */

/* Стили для контейнера формы */
.form-container {
  background-color: #444; /* Можно заменить на ваш основной цвет */
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  width: 300px; /* Задаем фиксированную ширину */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Стили для полей ввода */
.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.4s ease;
  background-color: #333; /* Цвет фона поля */
  color: white; /* Цвет текста */
}

.input-field:focus {
  border-color: #555; /* Можно заменить на ваш основной цвет */
  outline: none;
}

/* Стили для кнопок в форме */
.form-button {
  background-color: #444; /* Можно заменить на ваш основной цвет */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  transition: background-color 0.4s ease;
  margin: 10px 0;
  width: 100%;
}

.form-button:hover {
  background-color: #555; /* Можно заменить на ваш основной цвет */
}

/* Контейнер для кнопок в форме */
.form-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.form-button-container button {
  flex: 1;
  margin: 0 5px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Стили для переключателя табов */
.tab-switch {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.tab-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.4s ease;
}

.tab-button.active {
  border-bottom: 2px solid #fff; /* Можно заменить на ваш основной цвет */
}

.tab-button:hover {
  border-bottom: 2px solid #aaa; /* Можно заменить на ваш основной цвет */
}

/* Убедимся, что все текстовые элементы внутри формы белые */
.form-container,
.form-container * {
  color: white;
}

/* Стили для текста в форме */
.form-container .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.form-container .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.form-container .mb-4 {
  margin-bottom: 1rem;
}

/* Стили для выпадающего списка */
.form-container select.input-field {
  color: white;
  background-color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px; /* Место для стрелки */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='white'%3E%3Cpath d='M6 9L0 0h12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* Стили для опций в выпадающем списке */
.form-container select.input-field option {
  color: white;
  background-color: #333;
}